import React from 'react';
import Arrow from '../assets/img/SvgAccordionArrow';
import Collapsible from 'react-collapsible';
import { RichText } from 'prismic-reactjs';

const Accordion = ({ title, subtitle, isActive, setActive, style, tabIndex }) => {
  return (
    <Collapsible
      trigger={
        <>
          <div className="Accordion-title-container">
            <Arrow
              stroke={isActive ? '#00A499' : '#000'}
              style={
                isActive
                  ? { transform: 'rotate(90deg)', transition: 'all 0.3s ease-in-out' }
                  : { transform: 'rotate(0deg)', transition: 'all 0.3s ease-in-out' }
              }
            />
            <div
              className={`${isActive && 'color-primary-dark'} subtitle Accordion-title`}
            >
              {title}
            </div>
          </div>
        </>
      }
      easing="ease-in-out"
      open={isActive}
      tabIndex={tabIndex}
      className="Accordion-container"
      openedClassName="Accordion-inner-container"
      handleTriggerClick={setActive}
      transitionTime={300}
      transitionCloseTime={250}
      style={{ ...style }}
    >
      <div className="subtitle ">
        <RichText render={subtitle?.raw} />
      </div>
    </Collapsible>
  );
};

export default Accordion;
