import React, { useState } from 'react';
import Accordion from '../components/Accordion';
import Bg from '../assets/img/help_center_image.jpg';
import ImageCardContainer from '../components/ImageCardContainer';
import Seo from '../components/SEO';
import { navigate } from 'gatsby';

const HelpCenter = ({ pageContext }) => {
  const [active, setActive] = useState(null);

  const handleActive = (index) => {
    setActive(index === active ? null : index);
  };

  if (!pageContext) {
    return;
  }

  const pageLang = Object.keys(pageContext)[0];
  const pageData = Object.values(pageContext)[0];
  const homeTitle = Object.values(pageContext)[0]?.homeData?.page_name;

  const handleNavigate = () => {
    if (pageLang === 'en') {
      navigate('/');
      return;
    }
    navigate(`/${pageLang}`);
  };

  return (
    <>
      <Seo
        title={pageData?.meta_title}
        description={pageData?.meta_description}
        image={pageData?.meta_image?.url}
        lang={pageLang}
      />
      <div
        className="background-color-background"
        style={{
          overflowX: 'hidden',
        }}
      >
        <ImageCardContainer
          backgroundImage={Bg}
          nav={homeTitle}
          navTitle={pageData?.page_name}
          title={pageData?.title}
          description={pageData?.subtitle}
          handleNavigate={handleNavigate}
        >
          {pageData?.accordion_group &&
            pageData?.accordion_group.map((item, index) => {
              return (
                <Accordion
                  style={{ marginBottom: '1rem' }}
                  title={item.accordion_title}
                  subtitle={item.accordion_subtitle}
                  isActive={index === active}
                  setActive={() => handleActive(index)}
                  key={index}
                  tabIndex={index}
                />
              );
            })}
        </ImageCardContainer>
      </div>
    </>
  );
};

export default HelpCenter;
